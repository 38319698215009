import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
import SEO from '../components/seo';
import MountainsChart from '../components/chart-mountains';
import MountainsHeightChart from '../components/chart-mountains-height';
import MountainsHeightOverTimeChart from '../components/chart-mountains-height-over-time';
import { graphql } from 'gatsby';
import Img from "gatsby-image";
import MountainsOpenGraphImage from '../images/social-mountain-time.jpg';
export const pageQuery = graphql`
  query {
    imageOne: file(relativePath: { eq: "mountains.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3840) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Mountain Time" description="How mountain belts travel through time and around the globe." image={'https://storybook.earth' + MountainsOpenGraphImage} pageCanonical={'https://storybook.earth/mountains'} mdxType="SEO" />
    <Img className="story-header-gatsby-img" fluid={props.data.imageOne.childImageSharp.fluid} mdxType="Img" />
    <div className="story-content story-mountains">
      <p className="txt-low-emph txt-center">The crest of the Sierra Nevada mountains in eastern California</p>
      <div className="txt-center mvxxl">
    <h1 className="phm">Mountain Time</h1>
    <div className="mtm">
        <p className="mbn title-font">By Mike McDearmon</p>
        <p className="mtn txt-low-emph txt-center">Apr 22, 2020 | 5 min read</p>
    </div>
      </div>
      <p>{`Time is abundant for mountain belts. It takes millions of years for one to grow and millions more to erode away. While ample time can make mountain belts appear static, they are always on the move—pushed, pulled, and jumbled by the movement of the Earth's fragmented crustal plates. Some mountain belts have even traveled thousands of miles around the globe from their point of origin, carried by the drifting plates below.`}</p>
      <p>{`The Appalachians are one of the most ancient and well-traveled mountain belts still in existence today. Shadows of their former majesty, the Appalachians reached heights comparable to the Andes during the formation of the supercontinent Pangaea `}{`[1]`}{`. The Appalachians of this period are referred to by geologists as the Central Pangaean Mountains—one of the most extensive mountain belts on the planet that cut across Pangaea from east to west.`}</p>
      <p>{`Wind, water, and ice have since chiseled the high peaks of the Central Pangaean Mountains away, and tectonic activity has rifted them apart. Though separated by thousands of miles of Atlantic ocean, the modern Appalachians of North America, parts of the Atlas mountains of Morocco, and other fragments in Greenland, Scandinavia, the British Isles, Brittany, and the Iberian Peninsula were all formerly part of the same Central Pangean range.`}</p>
      <p>{`In contrast with the ancient and low-laying Appalachians, the loftiest mountain belts that exist today are also among the youngest. The Himalayas, Alps, and Causasus mountains all began to rise within the last 60 million years - unfathomably old in their own right, but relative youngsters compared to the Appalachians. In addition to their youth, these mountains all share a similar origin story. They are each the product of slow collisions between continents.`}</p>
      <p>{`Measured on a timescale of millions of years, the chart below offers a glimpse into the enormous lifespans of mountain belts. Periods of uplift are shown as horizontal bars (teal), and the blank spaces in between are periods of dormancy in which erosion has gradually weathered the mountains back down to Earth.`}</p>
      <div className="mtxxl mbxl txt-center">
        <h3>Growth Periods of Mountain Belts over time</h3>
        <h4 className="title-font mts mbxxl">with Current Highest Point of each belt</h4>
        <div style={{
          display: 'flex',
          maxWidth: '640px'
        }}>
          <MountainsChart mdxType="MountainsChart" />
          <MountainsHeightChart mdxType="MountainsHeightChart" />
        </div>
        <div className="davi-chart-bottom">
    <div className="x-axis-label mtn">Millions of Years Ago</div>
    <div className="legend mtm">
        <div className="legend_label mrm">
            <div className="label_line line-thick-nivo-teal"></div>
            <div>Mountain Growth Period</div>
        </div>
        <div className="legend_label mrm">
            <div className="label_line line-thick-nivo-orange"></div>
            <div>Current Height (Highest Point)</div>
        </div>
    </div>
        </div>
        <p className="phxl mbxxl caption">Older mountain belts have been slowly eroding away for millions of years, leaving behind remnants that are often shorter than their younger and actively growing successors. Growth period and height data are estimates from a host of studies [2][3][4][5].</p>
      </div>
      <p>{`It's difficult to fathom the age of mountain belts without some points of reference. When the Appalachians began to rise, for example, the Earth was a different planet. Land was mostly lifeless, like the surface of Mars, but with oceans that were home to the vast majority of life at the time.`}</p>
      <p>{`Later on as the Appalachians continued their first pulse skyward, plants were just beginning to inhabit dry land. Because neither wood or complex root structures had yet evolved, no plant life anywhere could have been taller than a few centimeters. The first tetrapods, four-limbed animals with characteristics of both fish and amphibians, wouldn't make their way onto land for another 90 million years `}{`[6]`}{`.`}</p>
      <p>{`But by the time the Appalachians reached their golden years as the Central Pangaean Mountains, dry land was teeming with both plant and animal life. Conifer-like forests, reptiles, and insects were common, and the animal kingdom was ruled by proto-mammals called synapsids. Reptiles wouldn't get their chance at the top of the animal kingdom for another twenty million years or so.`}</p>
      <div className="mtxxl mbxl txt-center">
        <h3>Height of Mountain Belts Over Time</h3>
        <h4 className="title-font mts">Relative to Key Events in Evolutionary History</h4>
        <div style={{
          display: 'flex',
          maxWidth: '640px'
        }}>
          <MountainsHeightOverTimeChart mdxType="MountainsHeightOverTimeChart" />
        </div>
        <div className="davi-chart-bottom">
    <div className="x-axis-label mtn">Millions of Years Ago</div>
    <div className="legend mtm">
        <div className="legend_label mrm">
            <div className="label_line line-medthick-nivo-teal"></div>
            <div>Appalachians</div>
        </div>
        <div className="legend_label mrm">
            <div className="label_line line-medthick-nivo-red"></div>
            <div>Rockies</div>
        </div>
        <div className="legend_label mrm">
            <div className="label_line line-medthick-nivo-orange"></div>
            <div>Himalayas</div>
        </div>
    </div>
        </div>
        <p className="phxl mbxxl caption">Milestones in biological evolution over the last 500 million years offer some perspective into the deep histories of mountain belts, particularly those as ancient as the Appalachians [7]. Historic height values shown here are approximations.</p>
      </div>
      <p>{`While mountain building periods are often measured in millions of years, there are times when the construction process is anything but slow. As the crust of the Earth compresses and expands when mountains are raised, it forms fractures at weak points in the bedrock. Pressure gradually builds until, like snapping a twig, a fracture gives way and the rock on one side slides past the other. What results is an earthquake, one of the rare occasions in which humans can witness the energy released when mountains move.`}</p>
      <p>{`A spectacular example occurred in 1872 at Owens Valley, California on the eastern edge of the Sierra Nevada range. The earthquake started in the middle of a moonlit night on March 26, taking residents of the nearby town of Lone Pine by surprise. Witnesses described the sensation of watching the moon bounce around the sky as the ground undulated beneath their feet: "Have you ever seen a boy at a campfire pick up a brand and wave it to and fro? Well, that was just the effect we got from this lunar display except that we were moving back and forth with the ground, while the moon was standing still!" `}{`[8]`}{`.`}</p>
      <p>{`When the shaking stopped, the eastern front of the Sierras had risen upward at least 16 feet and shifted horizontally as well. Earthquakes of this magnitude in the Sierras are estimated to occur roughly once every 500 years, and they account for a significant portion of the overall uplift of the range. What results is a growth pattern of fits and starts-massive bursts of earthquake energy surrounded by long interludes of quiet.`}</p>
      <p>{`A similar pattern is found in many growing mountain belts. The Himalayas, for example, remain one of the most seismically active regions of the world as India continues its collision into Asia—a collision that began around 50 million years ago. While mountain belts form and erode on a timescale difficult for humans to comprehend, their quaking is a reminder that our planet, from crust to core, is constantly in motion.`}</p>
      <div className="references mvxxl">
    <strong>References</strong>
    <ol>
        <li>Sam Evans-Brown, <a href="https://www.nhpr.org/post/ask-sam-were-white-mountains-once-tall-himalayas#stream/0" target="blank">Ask Sam: Were The White Mountains Once As Tall As The Himalayas?</a>, New Hampshire Public Radio, 2019</li>
        <li>Shota Adamia, <a href="https://www.researchgate.net/publication/279620884_Geology_of_the_caucasus_A_review" target="blank">Geology of the Caucasus: A Review</a>, Turkish Journal of Earth Sciences, 2011</li>
        <li>Rolando Armijo, Robin Lacassin, Aurélie Coudurier-Curveur, Danial Carrizo, <a href="https://www.sciencedirect.com/science/article/pii/S0012825215000148" target="blank">Coupled tectonic evolution of Andean orogeny and global climate</a>, Earth Science Reviews, 2015</li>
        <li>Jenna Lacurci, <a href="https://www.natureworldnews.com/articles/10511/20141120/explaining-the-formation-of-the-extraordinary-alaska-range.htm" target="blank">Explaining the Formation of the Extraordinary Alaska Range</a>, Nature World News, 2014</li>
        <li>Joseph M. English, Stephen T. Johnston, <a href="http://neotectonics.seismo.unr.edu/0_COURSES/Geo730-2020/EnglishLaramide2004.pdf" target="blank">The Laramide Orogeny: What Were the Driving Forces?</a>, International Geology Review, 2004</li>
        <li>Peter Brannen, <a href="https://www.harpercollins.com/9780062364814/the-ends-of-the-world/" target="blank">The Ends of the World</a>, HarperCollins, 2017</li>
        <li>Doug MacDougall, <a href="https://www.ucpress.edu/book/9780520272712/why-geology-matters" target="blank">Why Geology Matters</a>, University of California Press, 2011</li>
        <li>Mary Hill, <a href="https://www.ucpress.edu/book/9780520236967/geology-of-the-sierra-nevada" target="blank">Geology of the Sierra Nevada</a>, University of California Press, 2006</li>
    </ol>
      </div>
    </div>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      